import React from "react"

import Typography from "@material-ui/core/Typography"
import { documentToReactComponents } from "@contentful/rich-text-react-renderer"
import { BLOCKS } from "@contentful/rich-text-types"
import { graphql } from "gatsby"
import { useTranslation } from "gatsby-plugin-react-i18next"

import Layout from "../components/layout/layout"

import RowTitle from "../components/general/rowTitle"
import ArticlesRow from "../components/general/articlesRow"
import NewsletterBanner from "../components/general/newsletterBanner"

import Breadcrumbs from "../components/blog/breadcrumbs"

import useStyles from "./article.style"
import SEO from "../components/seo"
import { getImage } from "../helpers"

export const query = graphql`
  query($id: String, $categoryId: String, $language: String) {
    post: contentfulBlogPost(id: { eq: $id }) {
      seo {
        title
        description
        slug
      }
      createdAt
      title
      coverPicture {
        file {
          url
        }
      }
      author {
        name
      }
      title
      content {
        json
      }
      category {
        name
        seo {
          slug
        }
        category {
          name
        }
      }
    }
    suggestions: allContentfulBlogPost(
      limit: 3
      sort: { order: DESC, fields: createdAt }
      filter: { category: { id: { eq: $categoryId } } }
    ) {
      nodes {
        id
        title
        author {
          name
        }
        content {
          json
        }
        category {
          name
          seo {
            slug
          }
          category {
            name
          }
        }
        createdAt(fromNow: true, locale: $language)
        seo {
          slug
        }
      }
    }
  }
`

export default function Article({ data: { post, suggestions } }) {
  const classes = useStyles()
  const { t } = useTranslation()

  return (
    <Layout>
      <SEO
        title={post.seo.title}
        description={post.seo.description}
        blogPost={post}
      />
      <Breadcrumbs
        gutterTop
        center
        category={post.category}
        homeLink={post.category.category.name === "Blog" ? "/blog/" : "/"}
      />

      <div className={classes.headerWrapper}>
        <Typography
          className={classes.headerTitle}
          variant="h5"
          color="initial"
          align="center"
        >
          {post.title}
        </Typography>

        <div className={classes.authorWrapper}>
          {/* <CircledPicture size={48} /> */}

          <div className={classes.authorInfoWrapper}>
            <Typography variant="button" color="inherit">
              {post.author.name}
            </Typography>
            {/* <Typography variant="body1" color="textSecondary">
              Directrice de la com’ 🎙
            </Typography> */}
          </div>
        </div>
      </div>

      <img
        src={
          post.coverPicture
            ? getImage(post.coverPicture.file.url, true)
            : "https://images.unsplash.com/photo-1519943436883-fce407c06e40?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=2580&q=80"
        }
        className={classes.articleImage}
        alt="Article illustration"
      />

      <div className={classes.articleWrapper}>
        <Typography
          className={classes.articleParagraph}
          variant="button"
          color="initial"
        >
          {documentToReactComponents(post.content.json, {
            renderNode: {
              [BLOCKS.HEADING_2]: (_node, children) => <h2>{children}</h2>,
              [BLOCKS.EMBEDDED_ASSET]: node => (
                <img
                  src={`${node.data.target.fields.file["en-US"].url}?w=300&q=90`}
                  alt={node.data.target.fields.title["en-US"]}
                />
              ),
            },
          })}
        </Typography>
        <NewsletterBanner removeImage />
      </div>

      <RowTitle title={t("blog.suggestions")} />
      <ArticlesRow
        data={suggestions.nodes.map(suggestion => ({
          ...suggestion,
          href:
            suggestion.category.category.name === "Blog"
              ? `/blog/${suggestion.category.seo.slug}/${suggestion.seo.slug}`
              : `/${suggestion.category.seo.slug}/${suggestion.seo.slug}`,
        }))}
      />
    </Layout>
  )
}
