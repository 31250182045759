import { makeStyles } from "@material-ui/core/styles"

const useStyles = makeStyles(theme => ({
  headerWrapper: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    padding: theme.spacing(0, 2),
  },
  headerTitle: {
    maxWidth: 800,
  },
  authorWrapper: {
    margin: theme.spacing(2),
    display: "flex",
    alignItems: "center",
  },
  authorInfoWrapper: {
    marginLeft: theme.spacing(2),
    color: "#FFC400",
  },
  articleImage: {
    display: "block",
    maxWidth: 1080,
    margin: theme.spacing(0, 2),
    marginTop: theme.spacing(2),
    width: `calc(100% - ${theme.spacing(4)}px)`,
    borderRadius: theme.spacing(1),
    objectFit: "contain",
    position: "relative",

    [theme.breakpoints.up("md")]: {
      position: "absolute",
      left: 0,
      right: 0,
      zIndex: 0,
      margin: `${theme.spacing(2)}px auto`,
    },
  },
  articleWrapper: {
    position: "relative",
    zIndex: 1,
    margin: "0 auto",
    maxWidth: 920,
    padding: theme.spacing(3, 2, 3, 2),
    backgroundColor: "#FFF",
    borderRadius: theme.spacing(1),

    [theme.breakpoints.up("md")]: {
      padding: theme.spacing(6, 10, 3, 10),
      marginTop: 372,
    },
  },
  articleParagraph: {
    display: "block",
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(3),
  },
  joinBanner: {
    marginBottom: `${theme.spacing(3)}px !important`,
  },
}))

export default useStyles
